<template>
  <div class="index" v-html="data.content"></div>
</template>

<script>
  export default{
    data(){
      return{
        data:{}
      }
    },
    created() {
      this.getData()
    },
    methods:{
      async getData(){
        let res = await this.$http.get('/publicity/poster/getOnePoster',{params:{
          type: 1
        }})
        console.log(res)
        this.data = res.data.data
        this.data.content= this.data.content.replace(/<img/g, '<img style="width: auto;height: auto; max-width: 100%;max-height: 100%;" ');
        this.data.content = this.data.content.replace(
          /<iframe class="ql-video" frameborder="0" allowfullscreen="true"/g,
          '<video style="width: 100%;" controls><source ');
        this.data.content = this.data.content.replace(/<\/iframe>/g, ' type="video/mp4"></video> ');
      }
    }
  }
</script>

<style scoped="" lang="scss">
  .index{
    padding: 0 15vw;
  }
</style>
